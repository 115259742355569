import './src/styles.css';

import React, { ReactNode } from 'react';
import { Theme } from './src/components/Theme/Theme';
import { WindowProvider } from './src/providers/WindowProvider';

interface Props {
  element: ReactNode;
}

export const wrapRootElement = ({ element }: Props) => {
  return (
    <WindowProvider>
      <Theme>{element}</Theme>
    </WindowProvider>
  );
};
