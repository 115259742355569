import { DefaultTheme } from 'styled-components';

export const LightTheme: DefaultTheme = {
  palette: {
    primary: {
      black: '#151515',
      white: '#FFFFFF',
      blue: '#1099FD',
      dark: '#2A2A30',
    },

    gradients: {
      turkishSapphireGradient: 'linear-gradient(16.05deg, #477A8F 22.34%, #96C2DB 90.77%)',
      stoneGreyBlueMix: 'linear-gradient(23.48deg, #3F3E62 6.8%, #7D7FAD 80%)',
      greySilverGradient: {
        solid: 'linear-gradient(249.15deg, #BFC8DB 2.79%, #7A8599 97.66%)',
        alpha:
          'linear-gradient(249.15deg, rgba(191, 200, 219, 0.26) 2.79%, rgba(122, 133, 153, 0.26) 97.66%)',
      },
      blueOrangeTint: 'linear-gradient(262.45deg, #E8E2DB 8.5%, #D6DBF3 101.62%)',
      goldishGradient: 'linear-gradient(195.14deg, #E9AF86 12.69%, #9B674A 80.29%)',
      tintRoseGradient: 'linear-gradient(193.34deg, #C7AEE7 9.58%, #8C78A7 79.6%)',
      primaryBlueGradient: 'linear-gradient(50.33deg, #1099FD 2%, #74C4FF 81.35%)',
      primaryBlackGradient: 'linear-gradient(270deg, #232222 0%, #34354A 98.33%)',
    },

    greys: {
      lightLightGrey: '#F3F3F9',
      middleGrey: '#D6DDEA',
      darkGrey: '#ACB3C0',
      darkestGrey: '#7B7B8A',
      asphaltTint: '#9B9FB6',
    },

    negative: {
      contrastRed: '#FD4734',
      lessContrastRed: '#FF7878',
      lessContrastRedLight: 'rgba(255, 120, 120, 0.5)',
      lessContrastRedLighter: 'rgba(255, 120, 120, 0.2)',
    },

    positive: {
      contrastGreen: '#69C42D',
      lessContrastGreen: '#B9DB8D',
      lessContrastGreenLight: 'rgba(185, 219, 141, 0.5)',
      lessContrastGreenLighter: 'rgba(185, 219, 141, 0.2)',
    },
  },

  spacing: {
    xs: '5px',
    s: '10px',
    sm: '20px',
    m: '30px',
    l: '40px',
    xl: '50px',
    xxl: '60px',
  },

  /*
      Reference: https://getbootstrap.com/docs/5.0/layout/breakpoints/#available-breakpoints
      We took only the sweet spot for devices.
      This variable will and should be adjusted to our needs in the future.
   */
  breakpoints: {
    // Small devices (landscape phones, 576px and up)
    mobile: 'min-width: 576px',
    // Medium devices (tablets, 768px and up)
    tablet: 'min-width: 768px',
    // Large devices (laptops, 1200px and up)
    laptop: 'min-width: 1200px',
    // X-Large devices (small desktops, 1440px and up)
    smallDesktop: 'min-width: 1440px',
    // XX-Large devices (larger desktops, 1920px and up)
    desktop: 'min-width: 1920px',
  },

  typography: {
    desktop: {
      heroSecton: {
        fontFamily: '"Neue Plak Extended", sans-serif',
        fontWeight: 400,
        fontSize: '130px',
        lineHeight: '156px',
      },
      h1: {
        fontFamily: '"Neue Plak Extended", sans-serif',
        fontWeight: 400,
        fontSize: '121px',
        lineHeight: '145.2px',
      },
      h2: {
        fontFamily: '"Neue Plak Extended Semi", sans-serif',
        fontWeight: 400,
        fontSize: '48px',
        lineHeight: '62.4px',
      },
      h3: {
        fontFamily: '"Neue Plak Extended Semi", sans-serif',
        fontWeight: 400,
        fontSize: '36px',
        lineHeight: '46.6px',
      },
      h4: {
        fontFamily: '"Neue Plak Extended Semi", sans-serif',
        fontWeight: 400,
        fontSize: '30px',
        lineHeight: '39px',
      },
      h5: {
        fontFamily: '"Neue Plak Extended Semi", sans-serif',
        fontWeight: 400,
        fontSize: '24px',
        lineHeight: '31.2px',
      },
      h6: {
        fontFamily: '"Neue Plak Extended Semi", sans-serif',
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '23.4px',
      },
      gilroyButton: {
        fontFamily: '"Gilroy Semibold", sans-serif',
        fontWeight: 600,
        fontSize: '20px',
        lineHeight: '30px',
        letterSpacing: '1px',
      },
      largeBody: {
        fontFamily: '"Gilroy", sans-serif',
        fontWeight: 400,
        fontSize: '24px',
        lineHeight: '31.2px',
      },
      regularBody: {
        fontFamily: '"Gilroy", sans-serif',
        fontWeight: 400,
        fontSize: '20px',
        lineHeight: '30px',
        letterSpacing: '1px',
      },
      smallBody: {
        fontFamily: '"Gilroy", sans-serif',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '20.8px',
        fontStyle: 'normal',
      },
    },
    mobile: {
      h1: {
        fontFamily: '"Neue Plak Extended", sans-serif',
        fontWeight: 400,
        fontSize: '54px',
        lineHeight: '64.8px',
      },
      h2: {
        fontFamily: '"Neue Plak Extended Semi", sans-serif',
        fontWeight: 400,
        fontSize: '36px',
        lineHeight: '46.8px',
      },
      h3: {
        fontFamily: '"Neue Plak Extended Regular", sans-serif',
        fontWeight: 400,
        fontSize: '32px',
        lineHeight: '41.6px',
      },
      h4: {
        fontFamily: '"Gilroy Semibold", sans-serif',
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '31.2px',
      },
      regularBody: {
        fontFamily: '"Gilroy Medium", sans-serif',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '18.2px',
      },
      middleBody: {
        fontFamily: '"Gilroy Medium", sans-serif',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '20.8px',
      },
      largeBody: {
        fontFamily: '"Gilroy Medium", sans-serif',
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '23.4px',
      },
    },
  },
};
